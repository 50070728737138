/* clean white */
:root {
  --main-color: rgb(255, 255, 255);
  --second-color: #9f6705;
  --third-color: white;
  --hover-color: #e5f5ff;
  --hover-1: #e6fcff;
  --hover-2: #ffecee;
  --hover-3: #f9ecff;
  --hover-4: #faffe6;
  --almost-black: #2d2d2d;
  --white: #fff;
  --shadow: rgb(75, 75, 75);
  --default-font: 'Courier New', Courier, monospace;
  --nav__list__webwidth: 150px; 
}

body {
  background-color: var(--main-color);
}

.home {
  background-image: url("/public/home-snap.png");
  background-repeat: repeat-y;
  background-size:cover;
  height: 100vh;
  width: 100vw;
}

.content-1 {
  margin: auto;
  width: 75%;
}

.roundcontainer {
  /* background-color: white; */
  border-radius: 20px;
  width: 70%;
  min-width: 300px;
  max-width: 400px;
  top: 50%;
  transform: translateY(-50%);
  position: relative;
}

.linkbox {
  border-radius: 50px;
  /* border: 0.1px solid var(--almost-black); */
  /* background-color: rgb(255, 236, 239); */
  background-color: white;
  display: flex;
  margin-top: 20px;
  margin-right: auto;
  margin-left: auto;
  max-width: 300px;
  width: 90%;
  transition: 0.4s;
}

.linkbox:hover{
  background-color: var(--hover-1);
  transition: 0.5s;
}

.linkbox .linkbox-link
{
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 20px;
  text-decoration: none;
  width: 100%;
}

.linkbox .linkbox-link .icon {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2em;
  -webkit-font-smoothing: antialiased;
}

.linkbox .linkbox-link .link-title {
  font-size: 1.5em;
  text-align: center;
  padding-top: 16px;
  padding-bottom: 16px;
  width: 100%;
  color: var(--almost-black);
  transform: translateX(-5%);
}

.home-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
  padding-top: 10px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Verdana;
}

.panel {
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0px 2px 5px #bcbcbc;
}

.shadow {
  box-shadow: 0px 2px 5px #000000;
}

/* #spotify:hover {
  background-color: var(--hover-1);
}

#tiktok:hover {
  background-color: var(--hover-2);
}


#youtube:hover {
  background-color: var(--hover-3);
}

#apple:hover {
  background-color: var(--hover-4);
} */

.nav__list__web {
  height: 100%;
  background-color: var(--third-color);
  color: transparent;
  width: 62px;
  border-right: 0.5px solid rgb(225, 225, 225);
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  overflow-x: hidden;
  transition: 0.6s;
  overflow: auto;
}

.nav__list__web:hover {
 width: var(--nav__list__webwidth);
}

/* .nav__list__web:hover {
  width: 25vw;
  min-width:180px;
} */

.nav__list__web .logo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 60%;
  margin-bottom: 30px;
}

.nav__list__web a {
  text-decoration: none;
  font-size: 16px;
  /* font-family: ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", Segoe UI Symbol, "Noto Color Emoji"; */
  display: block;
}

.nav__list__web li {
  list-style: none;
  color: var(--almost-black);
  display: flex;
  transition: 0.3s;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  max-width: 180px;
}

.nav__list__web li:hover {
  background-color: var(--hover-color)
}

.nav__list__web li a .icon2 {
  position: relative;
  display: flex;
  color: var(--almost-black);
  margin-right: 10px;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
  font-weight: normal;
  min-width: 40px;
  height: 40px;
  -webkit-font-smoothing: antialiased;
}

.nav__list__web li a {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 1.25em;
  letter-spacing: 0.05em;
  transition: 0.5s;
}

.nav__list__web li Link {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 2.75em;
  letter-spacing: 0.05em;
  transition: 0.5s;
}

.nav__list__web li a .text {
  color: transparent;
  transition: 0.5s;
}

.nav__list__web:hover li a .text {
  color: var(--almost-black);
  transition: 0.5s;
}

footer {
  position: relative;
  background-color: white;
  left: 0;
  bottom: 0px;
  padding: 10px;
  width: 100%;
  text-align: center;
  border-top: 1px solid rgb(225, 225, 225);
}

.footerlink {
  padding-right: 10px;
}

/* fallback */
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v142/flUhRq6tzZclQEJ-Vdg-IuiaDsNcIhQ8tQ.woff2) format('woff2');
}

.center-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  border-radius: 20px;
  /* width: 30%; */
  /* min-width: 30vw; */
  max-height: 140vh;
  max-width: 130vh;
  margin-bottom: 20px;
  filter: brightness(0.5) grayscale(100%);
  transition: 1.0s;
}

.center-img:hover {
  /* filter: saturate(2); */
  filter: brightness(1.0);
  transition: 1.0s;
}

.thumb {
  /* display: block; */
  margin-left: 5%;
  margin-right: 5%;
  margin-bottom: 5%;
  /* filter: brightness(0.5); */
  border-radius: 5%;
  width: 15%;
}

.thumb:hover {
  /* display: block; */
  /* filter: brightness(1); */
}

.alignment {
  margin-left: 20%;
  margin-right: 20%;
  width: 90%;
}

h1 {
  font-size: 1.5em;
  text-align: center;
  padding-top: 0.5em;
}

/* from tutorial:https://blog.logrocket.com/create-responsive-navbar-react-css/  */
.nav__toggle,
.nav__close {
 font-size: 2.5rem;
 color: var(--almost-black);
 cursor: pointer;
}

.nav__toggle {
  margin-left: 90%;
}

.nav__close {
  position: absolute;
  top: 1rem;
  right: 1rem;
 }

 .nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  height: var(--header-height);
  margin: 1rem;
}

.nav__menu {
  position: fixed;
  top: 0;
  right: -100%;
  background-color: var(--main-color);
  backdrop-filter: blur(20px);
  /* -webkit-backdrop-filter: blur(16px); */
  width: 100%;
  height: 100%;
  padding: 6rem 3rem 0;
  transition: right 0.4s;
  z-index: 10;
}

.nav__menu .nav__list{
  margin-right: auto;
  margin-left: auto;
  width: 80%;
  list-style: none;
  color: var(--almost-black);
  transition: 0.3s;
  /* padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px; */
  max-width: 180px;
}

.nav__menu .nav__list li {
  list-style: none;
  width: 100%;
  margin-top: 50px;
  /* margin-bottom: 0px; */
  border: 0.7px black solid;
  border-radius: 10px;
  padding: 10px;
}

.nav__menu .nav__list li a {
  color: rgb(0, 0, 0);
  text-decoration: none;
  font-size: 30px;
  display: flex;
}

.nav__menu .nav__list li a .icon2{
  padding-right: 7px;
  display: flex;
  align-items: center;
}

.show-menu {
  right: 0%;
 }

.pdb {
  padding-bottom: 50px;
}

.maxw-400 {
  max-width: 400px;
}
